import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BmwExpCenterGlance = props => {
  return (
    <section className="bmw-locations">
      <div className="container">
        <div className="performance-center-locations">
          <h3>BMW EXPERIENCE CENTER AT A GLANCE</h3>
          <div className="row">
            <div className="col">
              <div>
                <h5>What is a BMW driving experience?</h5>
                <p>A BMW driving event that takes place over a few hours, as oppose to a full day class.</p>
              </div>
              <div>
                <h5>Where is the BMW Performance Center located?</h5>
                <p>We currently have three locations in California, South Carolina and Indianapolis.</p>
              </div>
              <div>
                <h5>BMW Performance Center</h5>
                <p>1155 SC-101, Greer, SC 29651<br/> (888) 345-4269</p>
              </div>
              <div>
                <h5>BMW Performance Center West</h5>
                <p>86-050 Jasper Lane, Thermal, CA 92274<br/> (888) 345-4269</p>
              </div>
              <div>
                <h5>Indianapolis Motor Speedway</h5>
                <p>4790 W 16th St, Indianapolis, IN 46222<br/> (888) 345-4269</p>
              </div>
              <div>
                <h5>Is the BMW Performance Center affiliated with area hotels?</h5>
                <p>Please visit our <a href="/hotelpartners">Hotels Page</a> for more information.</p>
              </div>
            </div>
            <div className="col">
              <div>
                <h5>Are BMW Driving Experiences offered at every location?</h5>
                <p>
                  There are half-day events in Thermal and South Carolina.
                </p>
              </div>
              <div>
                <h5>Can I drive on track during a BMW Driving Experience?</h5>
                <p>
                  Yes. Each event takes place on our closed tracks, just like our full-day events.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BmwExpCenterGlance;